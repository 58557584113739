'use client';
import { useTimer } from '@hooks/client';
import { useRouter } from 'next/navigation';
import React, { useMemo } from 'react';

import {
  Button,
  Illustration,
  IllustrationModal,
  Typography,
} from '@/shared/ui';

import styles from './desktop.module.scss';

export const NotFoundDesktop = () => {
  const router = useRouter();

  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <IllustrationModal variant={'404'} />
        <div className={styles['titles']}>
          <Typography className={styles['title']}>
            Oops, page not found...
          </Typography>
          <Typography className={styles['subtitle']}>
            Reload the page or return to the market
          </Typography>
        </div>
        <Button
          variant={'primary'}
          className={styles['action-btn']}
          text={'Go to market'}
          onClick={() => router.push('/')}
          iconLeft={
            <Illustration
              name={'square-arrow-left'}
              spriteName={'icons'}
              spanTagClassName={styles['icon']}
            />
          }
        />
      </div>
    </div>
  );
};
