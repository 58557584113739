'use client';
import { useRouter } from 'next/navigation';
import React from 'react';

import {
  Button,
  Illustration,
  IllustrationModal,
  Typography,
} from '@/shared/ui';

import styles from './mobile.module.scss';

export const NotFoundMobile = () => {
  const router = useRouter();

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        <div className={styles['body']}>
          <IllustrationModal variant={'404'} />
          <div className={styles['titles']}>
            <Typography className={styles['title']}>
              Oops, page not found...
            </Typography>
            <Typography className={styles['subtitle']}>
              Reload the page or return to the market
            </Typography>
          </div>
        </div>

        <div className={styles['footer']}>
          <Button
            variant={'primary'}
            className={styles['action-btn']}
            text={'Go to market'}
            onClick={() => router.push('/')}
            iconLeft={
              <Illustration
                name={'square-arrow-left'}
                spriteName={'icons'}
                spanTagClassName={styles['icon']}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
